<template>
  <div ref="capture" class="container-fluid main-container text-center bg1">
    <v-overlay v-if="loading" color=grey opacity=100>
      <v-progress-circular
      indeterminate
      color="primary"
      x-large
      class="mb-3"

    ></v-progress-circular>
    <h3>Загрузка списка агентов</h3>
    </v-overlay>
      <v-container v-if="!loading" class="agent-container mx-auto"
      v-bind:style="{ maxWidth: width + 'px' }">

        <div v-for="(agent, index) in data.agents" :key="index" class="p-3 mb-5">
          <img :src='agent.photo' v-bind:style="{ width: imgWidth + 'px' }" class="agentImage"><br>
          <span class="font-weight-bold">{{ agent.name }}</span>
        </div>
      </v-container>
      <div class="text-center mx-auto mt-3 title-container pb-3" v-if="!loading">
        <h3 class="h3 font-weight-bold text-uppercase">Лучшие специалисты компании</h3>
        <h1 class="h1 font-weight-bold text-uppercase">{{ data.office }}</h1>
        <h4>по итогам {{period}} квотного периода</h4>
      </div>

  </div>
</template>

<script>

export default {
  name: 'TopGrid',
  props: ['office', 'width', 'period', 'imgWidth'],
  data() {
    return {
      data: '',
      loading: false,
      url: 'https://weather.matveymatvey.com/gettop',
    };
  },
  watch: {
    office(newValue) {
      this.Gettop(newValue);
    },
  },
  methods: {

    async Gettop(office) {
      this.loading = true;
      await fetch(`${this.url}/${office}`, {
        method: 'GET',
      })
        .then((response) => {
          if (response.status === 500) {
            this.current_user = '500, Ошибка сервера API';
            throw new Error(response.status);
          } else return response.json();
        })
        .then((data) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.error('There was en error:', error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.Gettop(this.office);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-container {
  height: 100vh;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.bg1 {
  width: 100%;
  height: 100%;
  background: url('../assets/bg.png') !important;
}

.bg2 {
  background-color: #4169e1 !important;
}

.agent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
  padding-top: 100px;
  flex-wrap: wrap;
  max-width: 1050px;
  transition: all 0.5s ease;
  overflow-y: hide;
}
.agentImage {
  height: 100%;
  object-fit: cover;
}

.title-container {
  bottom: 0;
  left: 50%;
}

.wrapper {
  position: absolute;
width:100%;
min-height: 100%;
overflow: hidden;
}
</style>
