<template>
  <div class="home mt-5">
    <v-overlay v-if="loading" opacity=100 color=grey>
      <v-progress-circular
      indeterminate
      color="primary"
      x-large
      class="mb-3"
    ></v-progress-circular>
    <h3>Cохранение картинки</h3>
    </v-overlay>
    <h3>Создать доску почета</h3>
    <v-container>
    <v-form>
      <v-row>
        <v-col cols=3 class="text-left">
          <div class='mb-3'>
            <div class="subheading">Квотный период</div>
            <v-chip
            v-for="(item, index) in periods"
            class="mr-2"
            :key="index"
            small
            outlined
            :class="{ 'primary': item == period }"
            @click="period = item">{{item}}</v-chip>
          </div>

          <v-autocomplete
            v-model="office"
            :items="offices"
            label="Офис"
            item-text="text"
            item-value="value"
            return-object
          ></v-autocomplete>
        </v-col>

        <v-col cols=3 class="text-left">
          <div class="mb-3">
            <div class="subheading">Ширина блока с агентами, px</div>

            <v-chip v-for="(item, index) in widths"
              :key="index"
              outlined
              small
              class="mr-2"
              @click="width = item">
              {{item}}px
            </v-chip>
          </div>

          <v-text-field
            type="number"
            v-model="width"
          >
          </v-text-field>
        </v-col>
        <v-col cols=3 class="text-left">
          <div class="mb-3">
            <div class="subheading">Ширина фотографии агента, px</div>
            <v-chip v-for="(item, index) in imgWidths"
            outlined
            :key="index" small class="mr-2" @click="imgWidth = item">{{item}}px</v-chip>
          </div>
          <v-text-field
            type="number"
            v-model="imgWidth"
          >
          </v-text-field>

        </v-col>
        <v-col cols=3 class="text-left">
          <v-btn @click="saveImg" depressed color=success>Сохранить</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <div ref="capture" class="topgrid-wrapper">
    <TopGrid
      :office="office.value"
      :width="width"
      :imgWidth="imgWidth"
      :period="period"
    />
  </div>
  </div>
</template>

<script>
import html2canvas from '@wtto00/html2canvas';
import TopGrid from '@/components/TopGrid.vue';

export default {
  name: 'Home',
  components: {
    TopGrid,
  },
  data() {
    return {
      widths: [1500, 1400, 1300, 1100],
      imgWidths: [144, 135],
      width: 1400,
      imgWidth: 144,
      showTopGrid: false,
      office: { text: 'Адвекс-Центральное', value: 'adveks-tsentralnoe-agentstvo' },
      period: 'IV',
      periods: ['I', 'II', 'III', 'IV'],
      loading: false,
      offices: [
        { text: 'Адвекс - Центральное', value: 'adveks-tsentralnoe-agentstvo' },
        { text: 'Адвекс - Петроградский', value: 'adveks-petrogradskiy' },
        { text: 'Адвекс - Приморский', value: 'adveks-primorskiy' },
        { text: 'Адвекс - Озерки', value: 'adveks-ozerki' },
        { text: 'Адвекс - Санкт-Петербург', value: 'adveks-sankt-peterburg' },
        { text: 'Адвекс на Пионерской', value: 'adveks-na-pionerskoi' },
        { text: 'Адвекс Василеостровский', value: 'adveks-vasileostrovskiy' },
        { text: 'Адвекс - Лиговский', value: 'adveks-ligovskiy' },
        { text: 'Адвекс на Невском 100', value: 'adveks-nevskiy-100' },
        { text: 'Адвекс на Академической', value: 'adveks-na-akademicheskoy' },
        { text: 'Адвекс на Загородном', value: 'adveks-na-zagorodnom' },
        { text: 'Адвекс Купчино', value: 'adveks-kupchino' },
        { text: 'Адвекс на Дальневосточном', value: 'adveks-na-dalnevostochnom' },
      ],
    };
  },
  methods: {
    saveImg() {
      this.loading = true;
      const filename = `${this.office.value}.png`;
      html2canvas(this.$refs.capture, { useCORS: false, allowTaint: false, proxy: '/' }).then((canvas) => {
        this.saveAs(canvas.toDataURL(), filename);
      }).catch((error) => {
        console.log(`Ошибка при сохранении - ${error}`);
      });
    },
    saveAs(uri, filename) {
      const link = document.createElement('a');
      link.href = uri;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loading = false;
    },
  },
};
</script>
<style>
.topgrid-wrapper {
  position: absolute;
  width: 1920px;
  height: 915px;
}
</style>
